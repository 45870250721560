import { AfterViewInit, ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { getRouterSelectors } from '@ngrx/router-store';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { switchMap } from 'rxjs';

import { Store } from '@ngrx/store';

import { concatLatestFrom } from '@ngrx/operators';

import {
    Content,
    OivCoreSharedFacade,
    PAGE_URL_PATH,
    TealiumTrackingFacade,
    URL_PATH,
} from '@mobi/oiv-viewer-utils-ng-jslib';

import { OIV_TYPE } from '@mobi/oiv-viewer-xml-parser-ng-jslib';

import { ChapterPageComponent } from '../chapter-page.component';
import { ROUTE_PATHS } from '../../../../util/constant/route-paths';

import { filterDefined } from '../../../../util/filter-defined';

import { ChapterPageComponentStore } from '../../data/component-store/chapter-page.component-store.service';

import { OivViewerFacade } from '../../../core/data/facade/oiv-viewer-facade';

import { fromOivViewer } from '../../../core/data/store/oiv-viewer.selectors';

@Component({
    standalone: true,
    selector: 'oiv-viewer-chapter-page-with-topic-component',
    templateUrl: '../chapter-page.component.html',
    styleUrls: ['../chapter-page.component.scss'],
    imports: [CommonModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ChapterPageComponentStore],
})
export class ChapterPageWithTopicComponentComponent extends ChapterPageComponent implements OnInit, AfterViewInit {
    #store = inject(Store);
    #componentStore = inject(ChapterPageComponentStore);
    #oivViewerFacade = inject(OivViewerFacade);
    #tealiumTrackingFacade = inject(TealiumTrackingFacade);
    #oivCoreSharedFacade = inject(OivCoreSharedFacade);
    #destroyRef = inject(DestroyRef);

    #language = this.#oivCoreSharedFacade.currentLanguage;
    #urlName = this.#store.selectSignal(fromOivViewer.selectUrlName);
    #topicId = this.#store.selectSignal(getRouterSelectors().selectRouteParam(ROUTE_PATHS.ROUTE_PARAM_TOPIC_ID));

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.#store
            .select(getRouterSelectors().selectRouteParam(ROUTE_PATHS.ROUTE_PARAM_TOPIC_ID))
            .pipe(
                takeUntilDestroyed(this.#destroyRef),
                filterDefined,
                concatLatestFrom(() =>
                    this.#store
                        .select(getRouterSelectors().selectRouteParam(ROUTE_PATHS.ROUTE_PARAM_CHAPTER_ID))
                        .pipe(filterDefined),
                ),
                switchMap(([topicId, chapterId]) => {
                    return this.#oivViewerFacade.getChapter(chapterId);
                }),
            )
            .subscribe(chapterContent => {
                if (!chapterContent) {
                    // Navigate to the homepage if the chapter content is not found
                    this.router.navigate([
                        `${this.#language()}`,
                        `${URL_PATH.AVB}`,
                        `${this.#urlName()}`,
                        `${PAGE_URL_PATH.HOMEPAGE}`,
                    ]);
                } else {
                    this.#componentStore.setSelectedChapter(chapterContent);
                    const topics = chapterContent.content as Content[];
                    const selectedTopic = topics.find(topic => this.#topicId() === topic.eId);
                    // TODO - find the way to handle if there is no topic content
                    // if (!selectedTopic) {
                    //     // Go to the /topics route if there is no topic content
                    //     this.router.navigate([`../../../`], { relativeTo: this.route });
                    // } else {
                    //     this.#componentStore.setSelectedTopic(selectedTopic);
                    // }
                    if (selectedTopic) {
                        this.#componentStore.setSelectedTopic(selectedTopic);
                    }
                }
            });
        this.#render();
        this.scrollFragment();
    }

    #render() {
        this.#componentStore.getSelectedTopic$
            .pipe(
                takeUntilDestroyed(this.#destroyRef),
                // Combine the latest value from getSelectedChapter$ with the current value from getSelectedTopic$
                // concatLatestFrom ensure that the latest value from getSelectedChapter$ is only emitted (re-render) when getSelectedTopic$ emits a new value -> switching between topics in a chapter
                concatLatestFrom(() => this.#componentStore.getSelectedChapter$),
            )
            .subscribe(([topicContent, chapterContent]) => {
                if (!topicContent?.eId) return;
                this.ref.clear();
                this.renderContent(chapterContent, false);
                if (topicContent && topicContent.eId) {
                    this.renderContent(topicContent, false);
                    this.#tealiumTrackingFacade.trackPageView(topicContent, OIV_TYPE.TOPIC_TITLE);
                }
            });
    }
}
